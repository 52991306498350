import client from "@/api";
import store from "@/store";
import axios from "axios";

import {
  _rotaListarUsuarios,
  _rotaGerarRelatorioUsuarios,
  _rotaBloquearUsuario,
  _rotaInativarUsuario,
  _rotaDesbloquearUsuario,
  _rotaLogin,
  _rotaInformarGanhador,
  _rotaModerar,
  _rotaObterDadosModeracao,
  _rotaEsqueciSenha
} from "@/api/_caminhosApi";
import { limpaCPF } from "@/utils/masks";
import moment from "moment";

//
export function usuarioListarTodos(page, qtd, filtro, btn) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("usuario/SET_TABELA", "");
  
  let formData = new FormData();
  formData.append("Nome", filtro && filtro.nome ? filtro.nome : "")
  formData.append("Cpf", filtro && filtro.cpf ? limpaCPF(filtro.cpf) : "")
  formData.append("Email", filtro && filtro.email ? filtro.email : "")
  formData.append("DataCadastroInicial", filtro && filtro.dataInicial ? moment(filtro.dataInicial).format("YYYY-MM-DD T00:00:00") : "")
  formData.append("DataCadastroFinal", filtro && filtro.dataFinal ? moment(filtro.dataFinal).format("YYYY-MM-DD T00:00:00") : "")
  // formData.append("DataCadastroFinal", filtro && filtro.dataCadastroFinal ? moment(filtro.dataCadastroFinal).format("DD/MM/YYYY") : "")

  client
    .post(`${_rotaListarUsuarios()}?pagina=${page}&quantidadeItemPorPagina=${qtd}&filtroBotao=${btn}`, formData)
    .then(resp => {
      store.commit("usuario/SET_TABELA", resp.data.listaUsuarios);
      store.commit("usuario/SET_DATA_PAGINACAO", resp.data.paginacao);
    })
    .catch(erro => {
      console.log(erro);
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function GetExcelAdminById() {
	return new Promise( (resolve, reject) => {
		axios({
			url: `${process.env.VUE_APP_API_BASE}/Relatorio/ListarUsuario`,
			method: "GET",
			responseType: "blob",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
		})
		.then( res => { resolve(res) })
		.catch( err => { reject(err) })
	})	
}

export function bloquearUsuario(idUser, texto) {
  if(!texto){
    store.commit("interacoes/SET_MODAL_ALERTA", {
      ativado: true,
      mensagem: "Descreva o motivo do bloqueio.",
      tipo: "erro",
    });
    return
  }
  store.commit("interacoes/SET_LOADING", true);
  client
    .patch(_rotaBloquearUsuario(idUser, texto))
    .then(() => {
      store.commit("interacoes/SET_MODAL_ALERTA", {
        ativado: true,
        mensagem: "Usuário bloqueado com sucesso!",
        tipo: "sucesso",
      });
      setTimeout(() =>{
        document.location.reload(true);
      }, 2000)
    })
    .catch(erro => {
      store.commit("interacoes/SET_MODAL_ALERTA", {
        ativado: true,
        mensagem: erro.response.data.message,
        tipo: "erro",
      });
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
      this.$store.commit('interacoes/SET_ALERTA_BLOQUEIO', {
        ativado: false,
        mensagem: '',
        tipo: ''
      });
    });
}
export function inativarUsuario(idUser) {
  let formData = new FormData();
  formData.append('usuarioId', idUser)

  store.commit("interacoes/SET_LOADING", true);
  client
    .patch(_rotaInativarUsuario(), formData)
    .then(() => {
      store.commit("interacoes/SET_MODAL_ALERTA", {
        ativado: true,
        mensagem: "Usuário inativado com sucesso!",
        tipo: "sucesso",
      });
      store.commit("usuario/SET_USUARIO_TELA", "Lista");
    })
    .catch(erro => {
      store.commit("interacoes/SET_MODAL_ALERTA", {
        ativado: true,
        mensagem: erro.response.data.message,
        tipo: "erro",
      });
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
      this.$store.commit('interacoes/SET_ALERTA_BLOQUEIO', {
        ativado: false,
        mensagem: '',
        tipo: ''
      });
    });
}
export function desbloquearUsuario(idUser) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .patch(_rotaDesbloquearUsuario(idUser))
    .then(() => {
      store.commit("interacoes/SET_MODAL_ALERTA", {
        ativado: true,
        mensagem: "Usuário desbloqueado com sucesso!",
        tipo: "sucesso",
      });
    })
    .catch(erro => {
      store.commit("interacoes/SET_MODAL_ALERTA", {
        ativado: true,
        mensagem: erro.response.data.message,
        tipo: "erro",
      });
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
export function moderarUsuario(idUsuario, descricao, isBloqueado) {
  const idUsuarioAdmin =  window.localStorage.getItem('idUser')

  store.commit("interacoes/SET_LOADING", true);
  client
    .put(_rotaModerar(idUsuario, idUsuarioAdmin, descricao, isBloqueado))
    .then(() => {
      if(isBloqueado){
        store.commit("interacoes/SET_MODAL_ALERTA", {
          ativado: true,
          mensagem: "Usuário bloqueado com sucesso",
          tipo: "sucesso",
        });
      }else{
        store.commit("interacoes/SET_MODAL_ALERTA", {
          ativado: true,
          mensagem: "Usuário desbloqueado com sucesso!",
          tipo: "sucesso",
        });
      }
      
      setTimeout(() =>{
        document.location.reload(true);
      }, 2000)
    })
    .catch(erro => {
      store.commit("interacoes/SET_MODAL_ALERTA", {
        ativado: true,
        mensagem: erro.response.data.message,
        tipo: "erro",
      });
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
      store.commit("interacoes/SET_LOADING", false);
      this.$store.commit('interacoes/SET_ALERTA_BLOQUEIO', {
        ativado: false,
        mensagem: '',
        tipo: ''
      });
    });
}

export function gerarRelatorio() {
  return new Promise((resolve) => {
  store.commit("interacoes/SET_LOADING_RELATORIO", true);
  client
    .get(_rotaGerarRelatorioUsuarios())
    .then(resp => {
      console.log(resp)
      resolve(resp);
    })
    .catch(erro => {
      console.log(erro);
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING_RELATORIO", false);
    });
  });
}
export function esqueciSenha(email) {
  return new Promise((resolve) => {
  store.commit("interacoes/SET_LOADING_RELATORIO", true);
  client
    .get(_rotaEsqueciSenha(email))
    .then(resp => {
      resolve(resp);
    })
    .catch(erro => {
      console.log(erro);
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING_RELATORIO", false);
    });
  });
}
export function obterDadosModeracao(idUsuario) {
  return new Promise((resolve) => {
  store.commit("interacoes/SET_LOADING_RELATORIO", true);
  client
    .get(_rotaObterDadosModeracao(idUsuario))
    .then(resp => {
      store.commit("usuario/SET_DADOS_BLOQUEIO", resp.data);
      resolve(resp.data);
    })
    .catch(erro => {
      console.log(erro);
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING_RELATORIO", false);
    });
  });
}

export function informarGanhador(data) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .post(_rotaInformarGanhador(), data)
    .then(() => {
      store.commit("interacoes/SET_MODAL_ALERTA", {
        ativado: true,
        mensagem: "Ganhador salvo com sucesso!",
        tipo: "sucesso",
      });
    })
    .catch(erro => {
      store.commit("interacoes/SET_MODAL_ALERTA", {
        ativado: true,
        mensagem: erro.response.data,
        tipo: "erro",
      });
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function validarGanhador(senha, numeroSorte, cpf) {
  store.commit("interacoes/SET_LOADING", true);

  let newData = {
    email: localStorage.email,
    senha: senha,
  };

  let dataGanhador = {
    cpfUsuarioGanhador: cpf,
    numeroOrdem: Number(numeroSorte),
    idUsuarioAdm: JSON.parse(localStorage.idUser),
  };

  client
    .post(_rotaLogin(), newData)
    .then(resp => {
      informarGanhador(dataGanhador);
      store.commit("user/SET_LOGADO", true);
      window.localStorage.setItem("perfilId", resp.data.perfilAcessoPainel.id);
      window.localStorage.setItem("idUser", resp.data.usuario.usuario.id);
      window.localStorage.setItem("email", resp.data.usuario.usuario.email);
      window.localStorage.setItem("token", resp.data.token);
    })
    .catch(erro => {
      console.log("erro", erro);
      if (!erro.response || erro.response.status == 404) {
        store.commit("interacoes/SET_MODAL_ALERTA", {
          ativado: true,
          mensagem: "Não foi possível conectar ao servidor.",
          tipo: "erro",
        });
      } else {
        store.commit("user/SET_LOGADO", false);
        store.commit("user/SET_PERFIL", "");
        window.localStorage.removeItem("token");
        store.commit("interacoes/SET_MODAL_ALERTA", {
          ativado: true,
          mensagem: "Senha inválida",
          tipo: "erro",
        });
      }
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
