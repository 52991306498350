<template>
  <div name="modal" class="modal modal-retorno" :class="[modalAtivado]">
    <div class="modal-mask" v-if="modalAtivado"></div>
    <div class="modal-wrapper">
      <img class="modal-close" @click="fecharModal();" src="@/assets/images/icones/close.svg" />
      <div class="modal-container" :class="[modalAtivado]">
        <div class="modal-body">
          <h3 v-if="modal.tipoModal == 'bloquearUsuario'" class="titulo">Selecione o motivo da inativação:</h3>
          <h3 v-if="modal.tipoModal == 'reprovarCupom'" class="titulo">Selecione o motivo da reprovação:</h3>
          <div class="formulario-item">
            <multiselect v-model="motivo" :options="listaMotivoReprovaCupom" label="descricao"
              track-by="motivoReprovaId" :searchable="false" :allow-empty="false" placeholder="Selecione"
              select-label="Selecionar" deselect-label="Selecionado" selected-label="Selecionado" />
          </div>
          <div class="btns-formulario">
            <button v-if="modal.tipoModal == 'bloquearUsuario'" :disabled="!motivo" class="btn btn-enviar btn-reprovar"
              @click="() => { }">
              Bloquear
            </button>
            <button v-if="modal.tipoModal == 'reprovarCupom'" :disabled="!motivo" class="btn btn-enviar btn-reprovar"
              @click="motivoReprovaCupom">
              Reprovar
            </button>
            <button class="btn btn-cancelar" @click="fecharModal()">
              Voltar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inativarUsuario } from "@/api/usuario";
import { reprovarCupom } from "@/api/cupom";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      motivo: "",
      arrMotivos: [{ descricao: 'Sim', id: true }, { descricao: 'Não', id: false }]
    };
  },
  computed: {
    modal() {
      return this.$store.state.interacoes.modalBloqueia;
    },
    listaMotivoReprovaCupom() {
      return this.$store.state.cupom.motivoReprova
    },
    modalAtivado() {
      return this.modal.modalAtivado ? "ativo" : "";
    },
  },
  methods: {
    inativarUsuario,
    motivoReprovaCupom() {
      reprovarCupom(this.motivo.motivoReprovaId)
      this.fecharModal()
    },
    fecharModal() {
      this.$store.commit("interacoes/SET_MODAL_BLOQUEIO", {
        ativado: false,
      });
      this.motivo = ""
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
